.aboutComponent {
  .content {
    position: relative;
    display: block;
    width: 100%;
    height:100%;
    margin-top: 5%;
    padding-left: 3%;
    padding-right: 3%;
    min-height: 92vh;
    font-family: helvetica,arial,sans-serif;
    color: #525252;
    -webkit-font-smoothing: antialiased;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  
  }
  #nav-two{
    border-top: none;
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 5;
  }
  button{
    appearance: unset;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    align-items: unset;
    cursor: pointer;
    background-color: unset;
    box-sizing: unset;
    margin: unset;
    font: unset;
    padding: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
  }
  .nav-button{
    color: #222;
      text-decoration: none;
      font-size: 0.8rem;
      font-family: Helvetica,Arial,sans-serif;
      letter-spacing: .02rem;
  }
  hr{
    margin-bottom: 1%;
  }
  span{
    color: black;
    padding-bottom: 1%;
  }
  .information li{
    list-style-type: none;
    padding-bottom: 10%;
  }
  .information p{
    font-size: 80%;
    padding-bottom: 1%;
  
  }
  #bottom{
    font-size: 80%;
    padding-bottom: 3%;
  }
  .info-sidebar{
    position: relative;
    display: inline-block;
    height: 55px;
    width: 100%;
    -webkit-font-smoothing: antialiased;
  }
  
  
  .info-sidebar li{
  list-style-type: none;
  }
  .info-sidebar button{
    background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
    outline: inherit;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: Helvetica,Arial,sans-serif;
    letter-spacing: .02rem;
  }
  .info-sidebar button:hover{
    text-decoration: underline;
  }
  
  *{
      margin: 0px;
      padding: 0px;
      box-sizing: border-box;
  }
  
  nav{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 55px;
      /*background-color: #5D4954;*/
      background-color: black;
      font-family: 'Poppins', sans-serif;
      -webkit-font-smoothing: antialiased;
  }
  
  .logo{
      color: rgb(200, 200, 200);
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 20px;
      text-decoration: none;
  }
  
  .nav-links a{
    color: #222;
    text-decoration: none;
    font-size: 0.8rem;
    font-family: Helvetica,Arial,sans-serif;
    letter-spacing: .02rem;
  }
  .nav-links-two button{
    color: #222;
    text-decoration: none;
    font-size: 0.8rem;
    font-family: Helvetica,Arial,sans-serif;
    letter-spacing: .02rem;
  }
  .nav-links-two button:hover{
    color: rgb(132 132 132);
  }
  .burger{
      display: none;
      cursor: pointer;
  }
  
  .burger div{
      width: 25px;
      height: 3px;
      background-color: rgb(200, 200, 200);
      margin: 5px;
      transition: all 0.3s ease;
  }
  
  /*@media screen and (max-width:1024px){
      .nav-links{
          display: flex;
          justify-content: space-around;
          width: 60%;
      }
  }*/
  
  .nav-active{
      transform: translateX(0%);
      opacity: 1;
  }
  @keyframes navFade{
    from{
        opacity: 0;
        transform: translateX(50px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
  }
  @keyframes navLinkFade{
      from{
          opacity: 0;
          transform: translateX(50px);
      }
      to{
          opacity: 1;
          transform: translateX(0px);
      }
  }
    @keyframes searchbarShow{
      from{
          opacity: 0;
          transform: translateX(0px);
      }
      to{
          opacity: 1;
          transform: translateX(500px);
      }
  }
  @keyframes hideLegal{
    from{
        opacity: 90%;
    }
    to{
        opacity: 0;
        z-index: -1;
    }
  }
  
  .toggle .line1{
      transform: rotate(-45deg) translate(-5px,6px);
  }
  .toggle .line2{
  opacity: 0;
  
  }
  .toggle .line3{
      transform: rotate(45deg) translate(-5px,-6px);
  }
  
  a:hover{
    /* text-decoration: underline; */
    color: rgb(250, 250, 250);
  }
  
  .bi-search{
    color: rgb(200, 200, 200);
    vertical-align: middle;
  }
  .bi-search:hover{
    color: rgb(250, 250, 250);
  }
  .bi-x{
    color: rgb(200, 200, 200);
    vertical-align: middle;
  }
  .bi-x:hover{
    color: rgb(250, 250, 250);
  }
  .navbar-form{
    display: none;
    transition-timing-function: ease-out;
    transition: 2.0s;
    position: absolute;
  }
  .search-button{
    background-color: black;
    cursor: pointer;
    border: none;
    outline: none;
    vertical-align: middle;
  }
  .btn-default{
    background-color: black;
    cursor: pointer;
    border: none;
    outline: none;
    vertical-align: middle;
  }
  .exit-search{
    background-color: black;
    cursor: pointer;
    border: none;
    outline: none;
    vertical-align: middle;
    position: relative;
  }
  .form-control{
    padding-left:1em;
    width: 30vw;
    height: 5vh;
    background-color: black;
    border: none;
    outline: none;
    color: rgb(200, 200, 200);
    font-family: 'Poppins', sans-serif;
    font-size:18px;
    vertical-align: middle;
    letter-spacing: 0.5px;
  }
  .form-control:focus{
    color: rgb(200, 200, 200);
    font-family: 'Poppins', sans-serif;
    font-size:18px;
    letter-spacing: 0.5px;
    background-color:black;
  }
  .form-control::-webkit-search-cancel-button {
    display: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: rgb(200, 200, 200) !important;
    -webkit-font-family: 'Poppins', sans-serif !important;
    -webkit-font-size: 18px !important;
  }
  
  .link-site{
    text-decoration: none;
    color: black;
  }
  .link-site:hover{
    text-decoration: underline;
    color: black;
  }
  #main_image_header:hover {
    text-decoration: underline;
  }
  #second_image_header:hover {
    text-decoration: underline;
  }
  .logo:hover{
    color: rgb(200, 200, 200);
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 20px;
    text-decoration: none;
  }
  .logo-tag{
    color: rgb(200, 200, 200);
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 20px;
    text-decoration: none;
  }
  .logo-tag:hover{
    color: rgb(200, 200, 200);
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 20px;
    text-decoration: none;
  }
  .legal{
    background-color: white;
    position:absolute;
    padding:0;
    margin:0;
  
    top:0;
    left:0;
  
    width: 100%;
    height: 100%;
    text-align: center;
    display: inline-block;
    z-index: 10000;
    opacity: 90%;
  }
  .legal-span{
    display: block;
    position: relative;
    margin-top: 10%;  
    margin-left: 10%;
    margin-right: 10%;
    font-family: 'Poppins', sans-serif ;
    float: center;
  }
  .legal-span-two{
    display: block;
    position: relative;
    margin-top: 5%;  
    font-family: 'Poppins', sans-serif ;
    float: center;
  }
  .main_image_container:hover {
    text-decoration: underline;
  }
  .second_image_container:hover {
    text-decoration: underline;
  }
  .home{
    height: 50px;
    width: 100%;
    overflow:hidden;
    position: relative;
    display: inline-flex;
    justify-content: center;
    background-color: black;
    z-index: 1509;
  }
  .home .slider{
    position: relative;
    width: 500px;
  }
  .home .slide{
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  z-index:1; 
  display:none;
  padding:0 15px;
  /*animation: slide 2s ease;*/
  }
  .home .slide.active{
  display: flex;
  }
  /*@keyframes slide{
  0%{
    transform:scale(1.1);
  }
  100%{
    transform: scale(1);
  }
  }*/
  .container{
  max-width: 1170px;
  margin:auto;
  
  }
  
  .home .container{
   flex-grow: 1;
  }
  .home .caption{
    width:100%;
    text-align: center;
  }
  .home .caption h1{
  font-size:42px;
  color:#000000;
  margin:0;
  
  }
  .home .slide.active .caption h1{
  opacity:0;
  animation: captionText .5s ease forwards;
  animation-delay:1s;
  }
  .home .caption p{
  font-size: 11px;
  margin:15px 0 30px;
    color: rgb(167 167 167);
    font-family: Roboto Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    /*    color: rgb(53 53 53);*/
  }
  .home .slide.active .caption p{
  opacity:0;
  animation: captionText .5s ease forwards;
  animation-delay:1.2s;
  }
  .home .caption a{
  text-transform: uppercase;
  color: rgb(167 167 167);
  text-decoration: underline;
  }
  
  .home .slide.active .caption a{
  /*opacity:0;
  animation: captionText .5s ease forwards;
  animation-delay:1.4s;*/
  }
  
  @keyframes captionText{
  0%{
    opacity:0; transform: translateX(-100px);
  }
  100%{
   opacity:1; transform: translateX(0px);	
  }
  }
  @keyframes captionTextPrev{
  0%{
    opacity:0; transform: translateX(100px);
  }
  100%{
   opacity:1; transform: translateX(0px);	
  }
  }
  @keyframes captionTextLeave{
  0%{
    opacity:1; transform: translateX(0px);
  }
  100%{
     opacity:0; transform: translateX(100px);	
  }
  }
  @keyframes captionTextLeaveBackwards{
  0%{
    opacity:1; transform: translateX(0px);
  }
  100%{
     opacity:0; transform: translateX(-100px);	
  }
  }
  
  .home .controls .prev,
  .home .controls .next{
  position: relative;
  z-index:2;
  height:10px;
  width: 10px;
  color: rgb(167 167 167);
  text-align: center;
  line-height: 60px;
  font-size:20px;
  cursor:pointer;
  transition: all .5s ease;
  /*color: rgb(53 53 53);*/
  }
  
  .home .controls .prev{
    margin-right: 10px;
  }
  /*.home .controls .next{
  }*/
  
  .home .indicator{
  position: absolute;
  left:50%;
  bottom:-5px;
  z-index: 2;
    transform: translateX(-50%);
    visibility: hidden;
  }
  
  .home .indicator div{
  display: inline-block;
  width:25px;
  height: 3px;
  color:#ffffff;
  background-color: black;
  border-radius:0;
  text-align: center;
  line-height: 0px;
  margin:0 3px;
  }
  
  .home .indicator div.active{
  background-color: gray;
  }
  
  /*responsive*/
  /*@media(max-width: 767px){
  .controls{
    display: none;
  }
  }*/
  
  .above-nav{
    background-color: #171717;
    justify-content: center;
    width: 100%;
    height: 45px;
    text-align: center;
    position: relative;
  }
  p {
    margin-bottom: unset;
  }
  ul{
    margin-bottom: unset;
  }
  h4{
    margin-bottom: unset;
    font-weight: 500;
  }
  .top-span{
    display: inline-block;
    position:relative;
    height: 100%;
    text-align: center;
    line-height: 45px;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'DM Sans',sans-serif;
    color: #f1f1f1;
  }
  
    .left-nav-wrapper{
      height: 200px;
      width: 100px;
      float:left;
  }
  
  .category [id^=drop]{
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 80%;
    color: rgb(10,10,10);
    display: block;
    transition: 0.3s;
    cursor: pointer; 
  }
  .category [id^=drop]:hover{
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 80%;
    color: rgb(10,10,10);
    display: block;
    transition: 0.3s;
    cursor: pointer; 
  }
  .sidenav {
    height: 100%;
    width: 250px;
    /* position: fixed; */
    float: left;
    z-index: 1;
    top: 0;
    left: 0;
    /*background-color: #111;*/
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 50px;
    -webkit-font-smoothing: antialiased;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 80%;
    color: #818181;
    /*display: block;*/
    transition: 0.3s;
  }
  
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  .left_nav_hbar{
      /*padding: 0px,50px,0px,10px;*/
      width: 75%;
      display: inline-block;
      margin-left: 10px;
  }
  
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .sub-cat{
      display: none;
      padding-top: 10px;
  }
  
  .sub-cat a{
      display: block;
  }
  .bi-chevron-down{
  
      margin-right: 50px;
      transform: translate(0px, 3px) rotate(-0deg);
      float:right;
      transition: all 0.15s ease;
  }
  
  .bi-chevron-filter{
  
    margin-left: 15px;
    margin-top: 2px;
    transform: translate(0px, 3px) rotate(-0deg);
    float:right;
    transition: all 0.15s ease;
  }
  
  
    *{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }
    
    nav{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 55px;
      /*background-color: #5D4954;*/
      background-color: #fff;
      /* catch phrase above nav bar and probably sticky nav*/
      font-family: 'Poppins', sans-serif;
      -webkit-font-smoothing: antialiased;
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      
  }
  
  .logo{
      color: #222;
      /* #222 */
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      font-size: 25px;
      text-decoration: none;
  }
  
  
  .nav-links{
    display: flex;
    position: absolute;
    justify-content: space-evenly;
    width: 100%;
    padding-left: 30%;
    padding-right: 30%;
  }
  .nav-links-two{
    display: flex;
    position: absolute;
    justify-content: space-evenly;
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
  }
  
  .nav-links li{
      list-style: none;
  }
  .nav-links-two li{
    list-style: none;
  }
  /* .nav-links a{
      color: #222;
      text-decoration: none;
      font-size: 0.8rem;
      font-family: Helvetica,Arial,sans-serif;
      letter-spacing: .02rem;
  } */
  
  .burger{
      display: none;
      cursor: pointer;
  }
  
  .burger div{
      width: 25px;
      height: 3px;
      background-color: #4e4e4e;
      margin: 5px;
      transition: all 0.3s ease;
  }
   /* @media screen and (max-width:1024px){
        .nav-links{
            display: flex;
            justify-content: space-around;
            width: 60%;
        }
    }*/
    
  
  
  .nav-active{
      transform: translateX(0%);
      opacity: 1;
  }
  @keyframes navFade{
    from{
        opacity: 0;
        transform: translateX(50px);
    }
    to{
        opacity: 1;
        transform: translateX(0px);
    }
  }
  @keyframes navLinkFade{
      from{
          opacity: 0;
          transform: translateX(50px);
      }
      to{
          opacity: 1;
          transform: translateX(0px);
      }
  }
    @keyframes searchbarShow{
      from{
          opacity: 0;
          transform: translateX(0px);
      }
      to{
          opacity: 1;
          transform: translateX(500px);
      }
  }
  @keyframes navFadeUp{
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
  }
  
    .toggle .line1{
        transform: rotate(-45deg) translate(-5px,6px);
    }
    .toggle .line2{
    opacity: 0;
    
    }
    .toggle .line3{
        transform: rotate(45deg) translate(-5px,-6px);
    }
    a:hover{
      text-decoration: underline;
    }
    .nav-links a:hover{
      text-decoration: none;
      color: rgb(132 132 132);
    }
    .nav-links-two a:hover{
      text-decoration: none;
      color: rgb(132 132 132);
    }
  
    /* .filter:hover .filter-content{
      display: block;
    } */
    .top-bar{
      /* display: flex; */
      /* justify-content: space-around; */
      /* align-items: center; */
      min-height: 8vh;
      max-height: 8vh;
      /*background-color: #5D4954;*/
      /* background-color: red; */
      font-family: 'Poppins', sans-serif;
      -webkit-font-smoothing: antialiased;
    }
    #filter-btn
    {
      background:none;
      border:none;
      margin:0;
      padding:0;
      cursor: pointer;
      /* padding: 8px 8px 8px 32px; */
      text-decoration: none;
      font-size: 80%;
      color: #818181;
      font-family: 'Poppins', sans-serif;
      /*display: block;*/
      transition: 0.3s;
      display: block;
      margin: auto;
    }
    .filter-content{
      display: none;
    }
  
    .top-bar p{
      padding-left: 3%;
      display: inline-block;
      padding-top: 10px;
      margin-top: 5px;
    }
  
  
  
  
  .bi-search{
      color: rgb(200, 200, 200);
      vertical-align: middle;
  }
  .bi-search:hover{
      color: rgb(250, 250, 250);
  }
  .bi-x{
      color: rgb(200, 200, 200);
      vertical-align: middle;
  }
  .bi-x:hover{
      color: rgb(250, 250, 250);
  }
  
  .navbar-form{
      display: none;
      transition-timing-function: ease-out;
      transition: 2.0s;
      position: absolute;
  }
  .btn-default{
      background-color: black;
      cursor: pointer;
      border: none;
      outline: none;
      vertical-align: middle;
  }
  .exit-search{
      background-color: black;
      cursor: pointer;
      border: none;
      outline: none;
      vertical-align: middle;
      padding-top: 3px;
      position: relative;
  
  }
  .form-control{
      padding-left:1.5em;
      width: 30vw;
      height: 5vh;
      background-color: black;
      border: none;
      outline: none;
      color: rgb(200, 200, 200);
      font-family: 'Poppins', sans-serif;
      font-size:18px;
      vertical-align: middle;
      letter-spacing: 0.5px;
  }
  .form-control:focus{
      color: rgb(200, 200, 200);
      font-family: 'Poppins', sans-serif;
      font-size:18px;
      letter-spacing: 0.5px;
      background-color:black;
  }
  .form-control::-webkit-search-cancel-button {
      display: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px black inset !important;
      -webkit-text-fill-color: rgb(200, 200, 200) !important;
      -webkit-font-family: 'Poppins', sans-serif !important;
      -webkit-font-size: 18px !important;
  }
  
  
  .bi-chevron-left{
  
      /* margin-right: 50px; */
      transform: translate(0px, 6px) rotate(90deg);
      color:black;
      /* float:right; */
      /* transition: all 0.15s ease; */
  }
  .bi-chevron-right{
  
      /* margin-right: 50px; */
      transform: translate(0px, 6px) rotate(270deg);
      /* float:right; */
      /* transition: all 0.15s ease; */
      color:black;
  }
  
  .current{
    position:relative;
    font-family: Arial, Helvetica, sans-serif;
    transform: translate(0px, 4px);
    font-size: 80%;
  }
  
  .pagination{
    position: relative;
    /* bottom: 0; */
    /* right: 0; */
    float: right;
    /* display: inline-block; */
    /* margin-right: 2%; */
    width: 100%;
    z-index: 1002;
  }
  .step-links{
    float:right;
    margin-right: 2%;
    padding-bottom: 2%;
  }
  
  .step-links .sub-form{
  
  display: inline-block;
  
  }
    #page-btn{
      background:none;
      border:none;
      margin:0;
      cursor: pointer;
      padding: 0px 0px 0px 0px;
      text-decoration: none;
      font-family: 'Poppins', sans-serif;
      font-size: 80%;
      color: black;
      /*display: block;*/
      display: block;
      /* padding-bottom: 10em; */
      /* background-color: red; */
    }
  
    .result-amount{
      font-family: 'Poppins', sans-serif;
      font-size: 80%;
    }
    .no-results{
      font-family: 'Poppins', sans-serif;
      float:left;
      padding-left: 50px;
    }
    .link-site{
      text-decoration: none;
    }
    .title-section{
      font-size:140%;
    }
    .show-cat{
      font-size:140%;
    }
    .logo:hover{
      color: rgb(200, 200, 200);
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 20px;
      text-decoration: none;
  }
  .logo-tag{
      color: rgb(200, 200, 200);
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 20px;
      text-decoration: none;
  }
  .logo-tag:hover{
      color: rgb(200, 200, 200);
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 20px;
      text-decoration: none;
  }
  
  .filter-mobile{
    display: none;
  }
  .left_nav_hbar-mobile{
    /*padding: 0px,50px,0px,10px;*/
    width: 75%;
    display: none;
    margin-left: 10px;
  }
  .sidenav-x-btn{
    display: none;
  }
  .slider-btn{
    display:none;
  }
  .affiliate-notice{
    font-family: 'Poppins', sans-serif;
    font-size: 80%;
    width: 79%;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: inline-block;
    padding-left: 10px;
    margin-top: 10%;
    border-style: solid solid solid hidden;
    border-color: rgb(100,100,100);
    border-width: 1px;
    z-index: 1003;
    padding-bottom: 10px;
    padding-right: 5px;
  }
  .top-affiliate{
    padding-top: 10px;
  }
  .affiliate-open{
    font-family: 'Poppins', sans-serif;
    font-size: 80%;
    width: 10%;
    border-radius: 25px;
    display: inline-block;
    margin-left: 10px;
    margin-top: 10%;
    border-style: solid solid solid solid;
    border-color: rgb(100,100,100);
    border-width: 1px;
    text-align: center;
    position: fixed;
    bottom: 5px;
    left: -200px;
    cursor: pointer;
    z-index: 1003;
    background-color: white;
  }
  .affiliate-notice-mobile{
    font-family: 'Poppins', sans-serif;
    font-size: 50%;
    width: 50%;
    display: none;
    /* border-top-style: solid; */
    border-color: rgb(100,100,100);
    border-width: 1px;
    z-index: 1003;
    position: fixed;
    bottom:0px;
    left: 0px;
    background-color: white;
    padding-left: 2%;
  
  }
  .top-affiliate-mobile{
    padding-top: 10px;
  
  }
  .affiliate-open-mobile{
    font-family: 'Poppins', sans-serif;
    font-size: 50%;
    width: 22%;
    border-radius: 25px;
    display: none;
    margin-top: 10%;
    border-style: solid solid solid solid;
    border-color: rgb(100,100,100);
    border-width: 1px;
    text-align: center;
    position: fixed;
    bottom: 5px;
    left: -200px;
    cursor: pointer;
    z-index: 1003;
    background-color: white;
    margin-left: 2%;
    margin-bottom: 0.5%;
  
  }
  @keyframes AffiliateFade{
    from{
        opacity: 1;
        transform: translateX(0px);
    }
    to{
        opacity: 0;
        transform: translateX(-100px);
    }
  }
  @keyframes AffiliateFadeIn{
    from{
        opacity: 0;
        transform: translateX(-200px);
    }
    to{
        opacity: 1;
        transform: translateX(200px);
    }
  }
  @keyframes AffiliateFadeTopIn{  
    from{
    opacity: 0;
    transform: translateX(-100px);
  }
  to{
    opacity: 1;
    transform: translateX(0px);
  }
  }
  @keyframes AffiliateFadeNoteOut{
    from{
        opacity: 1;
        transform: translateX(200px);
    }
    to{
        opacity: 0;
        transform: translateX(-200px);
    }
  }
  
  
  
  
  /* NEW STUFF*/
  
  
  .toggle .line1{
    transform: rotate(-45deg) translate(-5px,6px);
  }
  .toggle .line2{
  opacity: 0;
  
  }
  .toggle .line3{
    transform: rotate(45deg) translate(-5px,-6px);
  }
  
  a:hover{
  /* text-decoration: underline; */
  color: rgb(132 132 132);
  }
  
  .bi-search{
  color: #222;
  vertical-align: middle;
  }
  
  .bi-search:hover{
  color: rgb(132 132 132);
  }
  .bi-x{
  color: rgb(200, 200, 200);
  vertical-align: middle;
  }
  .bi-x:hover{
  color: rgb(250, 250, 250);
  }
  .navbar-form{
  transition-timing-function: ease-out;
  transition: 2.0s;
  position: relative;
  width: 100%;
  display: inline-flex;
  border-bottom: 1px solid #ddd;
  display: none;
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  }
  .search-button{
  background-color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  vertical-align: middle;
  position: absolute;
  right: 0;
  margin-right: 3.5%;
  }
  .search-button span{
  padding-left: 0.2rem;
  color: #222;
  text-decoration: none;
  font-size: 0.8rem;
  font-family: Helvetica,Arial,sans-serif;
  letter-spacing: .02rem;
  vertical-align: middle;
  }
  
  .btn-default{
  background-color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  vertical-align: middle;
  position: relative;
  padding-left: 3%;
  }
  .exit-search{
  background-color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  vertical-align: middle;
  position: relative;
  display: none;
  }
  .form-control{
  padding-left:1em;
  width: 80%;
  height: 5vh;
  background-color: #fff;
  border: none;
  outline: none;
  color: rgb(40 40 40);
  font-family: 'Poppins', sans-serif;
  font-size:14px;
  vertical-align: middle;
  letter-spacing: 0.5px;
  position: relative;
  
  }
  .form-control:focus{
  color: rgb(40 40 40);
  font-family: 'Poppins', sans-serif;
  font-size:14px;
  letter-spacing: 0.5px;
  background-color:#fff;
  }
  .form-control::-webkit-search-cancel-button {
  display: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: rgb(200, 200, 200) !important;
  -webkit-font-family: 'Poppins', sans-serif !important;
  -webkit-font-size: 18px !important;
  }
  
  .link-site{
  text-decoration: none;
  color: black;
  }
  .link-site:hover{
  text-decoration: underline;
  color: black;
  }
  #main_image_header:hover {
  text-decoration: underline;
  }
  #second_image_header:hover {
  text-decoration: underline;
  }
  .logo:hover{
  /*color: rgb(200, 200, 200);
  text-transform: uppercase;
  letter-spacing: 3px;*/
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  }
  .logo-tag{
  color: rgb(200, 200, 200);
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 20px;
  text-decoration: none;
  position: absolute;
  left: 0;
  margin-left: 3%;
  z-index: 1;
  }
  .logo-tag:hover{
  /*color: rgb(200, 200, 200);
  text-transform: uppercase;
  letter-spacing: 3px;*/
  font-size: 20px;
  text-decoration: none;
  }
  .logo h4:hover{
  font-size:25px;
  cursor: pointer;
  letter-spacing: 0.15rem;
  color: #222;
  }
  
  
  /* NEW STUFF  */
  
  
  
  
  
  @media screen and (max-width:1024px){
    body{
        overflow-x: hidden;
    }
    .nav-links{
      position: fixed;
      right: 0px;
      height:100%;
      top: 0vh;
      padding-top: 60px;
      padding-bottom: 10%;
      z-index: 1000;
      /*background-color: #5D4954;*/
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      /* transform: translateX(100%); */
      transition: transform 0.5s ease-in;
      opacity: 0;
      display:none;
      border-left: 1px solid #ddd;
  
  }
  .nav-links-two{
  
    display:flex;
  
  }
  .home .caption p {
      line-height: unset;
  }
  .controls{
      display: none;
  }
  
    .nav-links li{
        opacity: 0;
    }
    .burger{
        display: block;
        z-index: 1509;
        position: absolute;
        right: 0;
        margin-right: 3%;
    }
    .clothing-item{
    
      width: 50%;
      height: 50%;
      padding: 1em;
      float: left;
    }
    .pic{
      max-width: auto;
      max-height: 10em;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    .clothing-content{
      font-family: 'Poppins', sans-serif;
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: 100px;
      height: 70px;
    }
    .sidenav {
      display: none;
      height: 100%;
      width: 100%;
      /* position: fixed; */
      float: left;
      z-index: 2000;
      top: 0px;
      left: unset;
      /*background-color: #111;*/
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: unset;
      position: absolute;
      background-color: white;
      overflow-y: scroll;
      
    }
    .item-wrapper{
      padding: 40px 10px 10px 10px;
    }
    .clothing-title{
      font-size: 1%;
    }
    
      .clothing-price{
      font-size: 1%;
      }
      .clothing-subtitle{
        font-size: 1%;
      }
      .filter {
        position: relative;
        display: inline-block;
        float: unset;
        align-items: center;
        padding-right: unset;
        margin-left: 10%;
        /* background-color: blue; */
      }
      .sidenav-x-btn{
        display: block;
        color: black;
        float: right;
        background-color: white;
        cursor: pointer;
        border: none;
        outline: none;
      }
      .slider-btn{
        display: unset;
        color: black;
        background-color: white;
        cursor: pointer;
        border: none;
        outline: none;
        position: relative;
        z-index:1505;
        /* float: right;
        margin-right: 30px;
        margin-top: 50px; */
        position: absolute;
        bottom: -40%;
        right: 10%;
  
      }
      .sidenav-header{
        width: 100%;
        height: 7%;
      }
      .left_nav_hbar{
        /*padding: 0px,50px,0px,10px;*/
        width: 95%;
        margin-left: 10px;
    }
    .top-bar{
      /* display: flex; */
      /* justify-content: space-around; */
      /* align-items: center; */
      min-height: 8vh;
      max-height: 8vh;
      /*background-color: #5D4954;*/
      /* background-color: red; */
      font-family: 'Poppins', sans-serif;
      position: relative;
    }
    .top-bar p{
      padding-left: 3%;
      padding-top: 0px;
      margin-top: 5px;
    }
    .filter-mobile{
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 80%;
    color: rgb(10,10,10);
    display: block;
    transition: 0.3s;
    cursor: pointer; 
    align-items: unset;
    }
    .drop-button{
      width: 100%;
      
      color: black;
      padding-top: unset;
      /* padding-right: 50px; */
      font-size: unset;
      border: none;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      background-color: white;
      /* margin: auto; */
      margin-top: unset;
      outline-color: white;
    }
    .drop-button span{
      float: left;
      font-size: 100%;
    }
    .left_nav_hbar-mobile{
      /*padding: 0px,50px,0px,10px;*/
      display: inline-block;
      width: 95%;
      margin-left: 10px;
    }
    #filter-btn{
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      padding: 8px 8px 8px 0px;
      text-decoration: none;
      font-family: 'Poppins', sans-serif;
      font-size: 100%;
      color: #818181;
      /* display: block; */
      transition: 0.3s;
      display: block;
    }
    .remove_filter{
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      padding: 8px 8px 8px 0px;
      font-weight: bold;
      font-family: 'Poppins', sans-serif;
      font-size: 100%;
      color: #141414;
      /* display: block; */
      transition: 0.3s;
      display: block;  
    }
    .filter{
      display:none;
    }
    
  .bi-sliders{
    width: 2.5em; 
    height: 2.5em;
  }
  .no-results{
    font-family: 'Poppins', sans-serif;
    float:left;
    padding-left: 25px;
    margin-top: 10%;
  }
  .step-links{
    float:right;
    margin-right: 8%;
    padding-bottom: 2%;
  }
  .affiliate-notice{
    display: none;
    }
  .top-affiliate{
  display: none;
  }
  .affiliate-open{
  display: none;
  }
  .affiliate-notice-mobile{
    display: inline-block;
  }
  .top-affiliate-mobile{
    display: inline-block;
  }
  .affiliate-open-mobile{
    display: inline-block;
  }
  
  nav{
    border-top: none;
  }
  .search-button{
    padding-top: unset;
    padding-bottom: unset;
    padding-right: unset;
    padding-left: unset;
    border: unset;
    margin-right: 25%;
    position: absolute;
  }
  .logo-tag{
    position: absolute;
  }
  .logo{
    letter-spacing: 0.1rem;
    font-size: 22px;
  }
  .search-button span {
    display: none;
  }
  .logo h4:hover{
    font-size:22px;
    cursor: pointer;
    letter-spacing: 0.1rem;
  }
  .no-mobile{
    display: none;
  }
  .stop-scrolling { 
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden; 
  } 
  .navbar-form{
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    }
  }
  .clear-btn{
    display: none;
    color: black!important;
  }
  
  
  .footer{
    position: relative;
    display: inline-block;
    width:100%;
    height:10vh; 
    background-color: rgb(250, 250, 250);
    font-family: 'Poppins', sans-serif;
    font-size: 75%;
    bottom: 0px;
  }
  .footer a{
  color: black;
  text-decoration: none;
  }
  .footer-links{
  position: relative;
  display: flex;
  justify-content: space-around;
  top: 42%;
  list-style-type: none;
  width: 100%;
  }
  .footer-links li:hover{
  text-decoration: underline;
  }
  .content-holder{
    min-height: 72vh;
    display: inline-block;
  }
  
  
  .footer{
        position: relative;
        width:100%;
        height:100px;   /* Height of the footer */
        background-color: rgb(250, 250, 250);
        font-family: 'Poppins', sans-serif;
        font-size: 75%;
        border-top: 1px solid #ddd;
  }
  .footer ul{
    padding-inline-start: unset;
  }
  .footer a{
    color: black;
    text-decoration: none;
  }
  .footer-links{
    position: relative;
    display: flex;
    justify-content: space-around;
    top: 42%;
    list-style-type: none;
    width: 100%;
  }
  
  .footer-links li:hover{
    text-decoration: underline;
  }
  .c-table{
    border: 1px solid #ddd;
    margin-bottom: 5%;
  
  }
  .c-table__td{
    border: 1px solid #ddd;
    text-align: center;
    padding: 2%;
  }
  .c-thead{
    border: 1px solid #ddd;
    text-align: center;
    display: table-header-group;
  
  
  }
  .c-thead__th{
    padding-top: 2%;
    padding-bottom: 2%;
    border: 1px solid #ddd;
    font-size: 80%;
    font-family: helvetica,arial,sans-serif;
    color: #222;
    background-color: #f5f5f5;
  }
  .c-table__tr{
    font-size: 70%;
    font-family: helvetica,arial,sans-serif;
    color: #525252;
  
  }
  table{
    border-collapse: collapse;
  }
  #smaller{
    font-size: 80%;
    padding-bottom: 0%;
    color: #1d1d1d;
    font-weight: bold;
  }
  #footer-hr{
    margin-bottom: 0%;
  }
  
  .info-ul{
    display: flex;
      justify-content: space-evenly;
  }
  
  
  .hearts{
    position: relative;
    min-height: 16px;
  }
  .bi-heart{
    display: unset;
  }
  .bi-heart:hover{
    color: rgb(132 132 132);
    cursor: pointer;
  }
  .bi-heart-fill{
    display: unset;
  }
  .bi-heart-fill:hover{
    fill: rgb(255, 94, 94);
    cursor: pointer;
  }
  .hearts:hover{
    fill: rgb(132 132 132);
    cursor: pointer;
  }
  .menu-heart{
    cursor: pointer;
    border: none;
    outline: none;
    vertical-align: middle;
    position: absolute;
    right: 0;
    margin-right: 10%;
  }
  @media screen and (max-width:1024px){
    .menu-heart{
      margin-right: 30%;
    }
    .search-button{
      margin-right: 18%;
    }
    }
    @media screen and (max-width:320px){
      .menu-heart{
        margin-right: 26%;
      }
      .search-button{
        margin-right: 17%;
      }
      }
  
    }