.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Toastify__toast--info {
  font-size: 13px;
}
:root {
  --toastify-icon-color-info: #047810a1;
  --toastify-color-progress-info: #047810a1;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.text-swipe{
  font-family: 'Newsreader';
  font-size: 13px;
  text-transform: uppercase;
}
.swiper-slide{
  display: flex !important;
  justify-content: center !important;
}
.swiper-initialized{
  margin: auto !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
